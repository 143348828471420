import React, { useEffect, useRef } from "react"
import * as styles from "./styles.module.scss"
import { Button, Container } from "react-bootstrap"
import LeftCardProfile from "../../../components/layout/LeftCardProfile"
import axios from "axios"
import ButtonProfile from "../../shared/ButtonProfile"

import { navigate } from "gatsby"
import SpinLoad from "../../shared/Spinner"
import Modal from "react-bootstrap/Modal"

const Profile = () => {
	const [user, setUser] = React.useState([])
	const [firstName, setFirstName] = React.useState("")
	const [lastName, setLastName] = React.useState("")
	const [license, setLicense] = React.useState("")
	const [phone, setPhone] = React.useState("")
	const [locationId, setLocationId] = React.useState("")
	const [emailAddress, setEmailAddress] = React.useState("")
	const [userID, setUserId] = React.useState("")
	const [userToken, setUserToken] = React.useState("")
	const firstUpdate = useRef(true)
	const [location, setLocation] = React.useState([])
	const [loading, setLoading] = React.useState(false)
	const [isChanged, setIsChanged] = React.useState(false)
	const [profileSaveModal, setProfileSaveModal] = React.useState(false)
	const [profileSaveErrorModal, setProfileSaveErrorModal] = React.useState(false)

	useEffect(() => {
		console.log(userToken)
		console.log(userID)
	}, [userToken, userID])

	//Set Data on LocalStorage
	useEffect(() => {
		localStorage.setItem("userData", JSON.stringify(user))
	}, [user])

	//Get Data From Local Storage
	// 	useEffect(() => {
	// 		const userid = localStorage.getItem("userId")
	// 		if (userid) {
	// 			setUserId(userid)
	// 		}
	// 		const usertoken = localStorage.getItem("token")
	// 		if (usertoken) {
	// 			setUserToken(usertoken)
	// 		}
	// 	},[])
	//
	// console.log(userID)
	// console.log(userToken)

	//Patch UseEffect
	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false
			return
		}
		setIsChanged(true)
		const update = {
			first_name: firstName ? firstName : user.first_name,
			last_name: lastName ? lastName : user.last_name,
			email: emailAddress ? emailAddress : user.email,
			license: license ? license : user.license,
			phone: phone ? phone : user.phone,
			location_id: locationId ? locationId : user.locationId,
		}

		axios
			.put("https://api.galeahealth.com/app/user", update, {
				headers: {
					Authorization: userToken, // contentType: 'multipart/form-data',
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data)
				}
			})
			.catch((err) => {
				console.log(err)
				setProfileSaveErrorModal(true)
			})
	}, [firstName, lastName, phone, emailAddress, license, locationId])

	useEffect(() => {
		setLoading(true)
		const id = localStorage.getItem("userId")
		const token = localStorage.getItem("token")
		setUserToken(token)
		axios
			.get(`https://api.galeahealth.com/app/user/${id}`, {
				headers: {
					Authorization: token,
				},
			})
			.then((res) => {
				console.log(res.data)
				setUser(res.data)
				setLoading(false)
				// const userData = res.data
				// localStorage.setItem("userData", JSON.stringify(userData))
			})
			.catch((err) => {
				console.log(err)
			})

		axios
			.get("https://api.galeahealth.com/app/location", {
				headers: {
					Authorization: userToken,
				},
			})
			.then((res) => {
				// console.log(res.data);
				setLocation(res.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const handleLocation = (id) => {
		setLocationId(id)
	}

	return (
		<>
			{loading ? (
				<div className="d-flex justify-content-center align-items-center" style={{ width: "100%", height: "75%" }}>
					<SpinLoad />
				</div>
			) : (
				<div className={styles.providerProfileMain}>
					<div>
						<Container className="">
							<div className="row pt-5">
								<div className="col-md-12 col-lg-4 pb-3">
									<LeftCardProfile id={user.id} img={user.image_url} name={user.name} />
								</div>
								<div className="col-md-12 col-lg-8">
									<div className={styles.rightCard1}>
										<div className={styles.account}>
											<h4 className="text-center">Step 1: Contact Information</h4>
											<p className="text-center">Please fill in the information below to complete your profile</p>
										</div>
									</div>
									<div className={`${styles.add} ${styles.contactInfo}`}>
										<h3 className="mt-5">Contact Information</h3>
									</div>
									<div className={styles.rightCard2}>
										<div className="row">
											<div className="col-md-4">
												<div>
													<span className={styles.floatingLabel}>First Name*</span>
													<input
														type="text"
														onBlur={(e) => setFirstName(e.target.value)}
														defaultValue={user.first_name}
														className={styles.inputText}
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div>
													<span className={styles.floatingLabel}>Last Name*</span>
													<input
														type="text"
														defaultValue={user.last_name}
														onBlur={(e) => setLastName(e.target.value)}
														className={styles.inputText}
													/>
												</div>
											</div>

											<div className="col-md-4">
												<div className="form-group mt-4">
													<select
														onChange={(e) => {
															handleLocation(e.target.value)
														}}
														className={styles.formControl}
													>
														{/*<option value="0: 21">{user?.location?.title}</option>*/}
														{location &&
															location.map((item, index) => (
																<option key={index} selected={item.id === user.locationId} value={item.id}>
																	{item.title}
																</option>
															))}
													</select>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-4">
												<div>
													<span className={styles.floatingLabel}>Licenses</span>
													<input
														type="text"
														defaultValue={user.license}
														onBlur={(e) => setLicense(e.target.value)}
														className={styles.inputText}
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div>
													<span className={styles.floatingLabel}>Phone*</span>
													<input
														type="text"
														// value={phone}
														defaultValue={user.phone}
														onBlur={(e) => setPhone(e.target.value)}
														className={styles.inputText}
													/>
												</div>
											</div>
											<div className="col-md-4">
												<div>
													<span className={styles.floatingLabel}>Email*</span>
													<input
														type="text"
														defaultValue={user.email}
														onBlur={(e) => setEmailAddress(e.target.value)}
														className={styles.inputText}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="text-end mt-5">
										<ButtonProfile
											onClick={() => {
												if (isChanged) {
													setProfileSaveModal(true)
												} else {
													navigate(`/profileDetails`)
												}
											}}
										>
											Next
										</ButtonProfile>
									</div>
								</div>
							</div>
						</Container>
						<Modal
							centered
							size="lg"
							show={profileSaveModal}
							onHide={() => {
								setProfileSaveModal(false)
								navigate(`/profileDetails`)
							}}
						>
							<Modal.Body>
								<div className="text-center pb-3 pt-5">
									<span className="patti"></span>
									<h6 className="patti">Profile saved!</h6>
									<p>Your profile information was successfully saved.</p>
									<Button
										className="btn btn-primary"
										style={{ backgroundColor: "#007bff", color: "#fff" }}
										onClick={() => navigate("/profileDetails")}
									>
										OK
									</Button>
								</div>
							</Modal.Body>
						</Modal>
						<Modal centered size="lg" show={profileSaveErrorModal} onHide={() => setProfileSaveErrorModal(false)}>
							<Modal.Body>
								<div className="text-center pb-3 pt-5">
									<span className="patti"></span>
									<h6 className="patti">Profile save Error!</h6>
									<p>There is something error in Profile information saving!</p>
									<Button
										className="btn btn-primary"
										style={{ backgroundColor: "#007bff", color: "#fff" }}
										onClick={() => setProfileSaveErrorModal(false)}
									>
										OK
									</Button>
								</div>
							</Modal.Body>
						</Modal>
					</div>
				</div>
			)}
		</>
	)
}

export default Profile
