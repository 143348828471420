import * as React from "react"
import { Main } from "../components/layout"
import Profile from "../components/pages/Profile"

// markup
const ProfilePage = () => {
	return (
		<>
			<Main>
				<Profile />
			</Main>
		</>
	)
}

export default ProfilePage
